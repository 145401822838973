import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/private-policy/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/private-policy/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/politicia-de-privacidad/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/politica_de_privacidade/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/private-policy/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/private-policy/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent">
                <h2>POLÍTICA DE PRIVACIDAD</h2>
                <p> </p>
                <p>
                  <strong>JNTL Consumer Health (Spain), S.L.</strong> se preocupa por
                  su privacidad y desea que esté familiarizado con cómo
                  recopilamos, utilizamos y divulgamos información. Esta
                  Política de privacidad describe nuestras prácticas en relación
                  con la información que nosotros o nuestros proveedores de
                  servicios recopilan a través del sitio web o aplicación (en
                  adelante el <strong>“Servicio”</strong>) operados y
                  controlados por nosotros, desde los cuales usted accede a esta
                  Política de privacidad. Al proporcionarnos información
                  personal o utilizar el Servicio, usted declara que ha leído y
                  comprendido esta Política de privacidad.{" "}
                </p>
                <h2 className="p1">
                  <strong>USO POR PARTE DE MENORES DE EDAD</strong>
                </h2>
                <p>
                  El Servicio no está dirigido a personas menores de{" "}
                  <strong>14 años,</strong> y le pedimos a estas personas que no
                  proporcionen información personal en el Servicio. Si su hijo
                  ha enviado Información personal y usted desea solicitar que
                  dicha Información personal sea eliminada, por favor
                  contáctenos tal y como se explica más adelante en la sección
                  Contactar con nosotros.
                </p>
                <h2 className="p1">
                  <strong>RECOGIDA DE INFORMACIÓN</strong>
                </h2>
                <p>
                  Podemos solicitarle que envíe información personal para que se
                  beneficie de ciertas características (como suscripciones a
                  boletines, consejos/indicadores o procesamiento de pedidos) o
                  para participar en una actividad particular (como sorteos u
                  otras promociones).
                  <span className="Apple-converted-space"></span> Se le
                  comunicará qué información es necesaria y cuál es opcional.
                </p>
                <p>
                  Podremos combinar la información que usted proporcione con
                  otros datos que hayamos obtenido de usted, ya sea en línea o
                  no, incluido, por ejemplo, su historial de compra.{" "}
                  También podemos combinarla con información sobre usted que recibamos de otras filiales de Kenvue. {" "}
                  <span className="Apple-converted-space"> </span>
                </p>
                <p>
                  Si nos envía cualquier información personal relativa a otro
                  individuo, usted declara que tiene autorización para hacerlo y
                  para permitirnos usar la información de acuerdo con esta
                  Política de privacidad.
                </p>
                <h2 className="p1">
                  <strong>INFORMACIÓN SENSIBLE</strong>
                </h2>
                <p>
                  Salvo que se lo solicitemos o se le invite específicamente, le
                  pedimos que no nos envíe ni divulgue información personal
                  sensible (p. ej., número de la seguridad social, información
                  relacionada con el origen racial o étnico, opiniones
                  políticas, religión u otras creencias, salud o afecciones médicas, vida sexual u
                  orientación sexual, antecedentes penales o asociación
                  sindical, o datos genéticos o biométricos) en el Servicio o a través de este, o de otro modo a nosotros.
                </p>
                <h2 className="p1">
                  <strong>USO Y RECOPILACIÓN AUTOMÁTICA DE INFORMACIÓN</strong>
                </h2>
                <p>
                  Nosotros y nuestros proveedores de servicios podemos recopilar
                  cierta información de forma automática mientras navega por el
                  Servicio. <span className="Apple-converted-space"></span>Lea la{" "}
                  <span className="s1">
                    <a href="/es/legal/politicia-de-cookies/" target="_blank">
                      Política de cookies
                    </a>
                  </span>{" "}
                  para obtener información detallada sobre las cookies y otras
                  tecnologías de rastreo empleadas en el Servicio. Excepto cuando lo permita la ley aplicable, 
                  instalaremos las cookies una vez hayamos recibido su consentimiento mediante el banner de 
                  cookies o su centro de preferencias.{" "}
                  <span className="Apple-converted-space"></span>La Política de
                  cookies incluye información sobre cómo puede desactivar estas
                  tecnologías.<span className="Apple-converted-space"></span>
                </p>
                <p>
                  Nosotros y nuestros proveedores de servicios también podemos
                  usar y recopilar automáticamente información de las siguientes
                  formas:{" "}
                </p>
                <ul>
                  <li>
                    <strong>A través de su navegador</strong>:
                    <span className="Apple-converted-space"> </span>La mayoría
                    de los navegadores recopilan cierta información, como su
                    dirección de Control de Acceso a Medios (Media Access
                    Control, MAC), tipo de ordenador (Windows o Mac), resolución
                    de pantalla, nombre y versión del sistema operativo, y el
                    tipo y la versión del navegador de Internet.
                    <span className="Apple-converted-space"></span> Podremos
                    recopilar información similar, como su tipo de dispositivo e
                    identificador, si accede al Servicio a través de un
                    dispositivo móvil.
                    <span className="Apple-converted-space"></span>&nbsp;Utilizamos
                    esta información para asegurar que el Servicio funcione
                    adecuadamente.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Dirección IP</strong>:
                    <span className="Apple-converted-space"> </span>Su dirección
                    IP es un número que su Proveedor de Servicios de Internet
                    (Internet Service Provider, ISP) asigna automáticamente a su
                    ordenador.<span className="Apple-converted-space"></span>&nbsp;La
                    dirección IP se identifica y registra automáticamente en los
                    archivos de registro de nuestro servidor cada vez que un
                    usuario visita el Servicio, junto con la hora de su visita y
                    las páginas que visitó.
                    <span className="Apple-converted-space"></span>&nbsp;La recogida
                    de direcciones IP es una práctica estándar y muchos
                    servicios en línea la realizan de forma automática.{" "}
                    <span className="Apple-converted-space"></span>Usamos las
                    direcciones IP para calcular los niveles de uso del
                    Servicio, diagnosticar problemas en servidores y administrar
                    el Servicio.{" "}<span className="Apple-converted-space"></span>
                    También podemos deducir su ubicación aproximada desde su
                    dirección IP.
                    <br />
                    <br />
                  </li>
                  <li>
                    <strong>Información de dispositivos</strong>:
                    <span className="Apple-converted-space"> </span>Podemos
                    recopilar información sobre su dispositivo móvil, como el
                    identificador único de dispositivo, para entender cómo
                    utiliza el servicio.
                    <br />
                    <br />
                  </li>
                </ul>
                <h2>CÓMO USAMOS Y DIVULGAMOS LA INFORMACIÓN</h2>
                <div>
                  <p className="p1">
                    Usamos y divulgamos la información que nos proporciona según
                    lo mencionado en el momento de la recopilación. Por favor
                    consulte la sección titulada “Opciones y accesos” más
                    adelante para saber cómo puede quedar excluido de ciertos de
                    nuestros usos y divulgaciones.
                  </p>
                  <p className="p1">
                    Cuando lo requiera la legislación aplicable, obtendremos su
                    consentimiento para el uso que hagamos de su información
                    personal en el momento de la recopilación de información.
                    Cuando lo requiera la legislación aplicable, obtendremos 
                    su consentimiento para el uso que hagamos de su información 
                    personal en el momento de la recopilación de información. 
                    Asimismo, podemos usar información suya o sobre usted tal y 
                    como sea necesario para ejecutar un contrato, cumplir una obligación 
                    legal (por ejemplo, debido a nuestras obligaciones sobre farmacovigilancia) o para nuestros intereses 
                    comerciales legítimos.
                    También podemos basarnos en otras bases legales,
                    específicamente para:
                  </p>
                  <p className="p1"> </p>
                  <ul>
                    <li>
                      <strong>
                        Proporcionar la funcionalidad del Servicio y atender sus
                        solicitudes.
                      </strong>
                    </li>
                    <li>
                      para proporcionarle la funcionalidad del Servicio y
                      proporcionarle un servicio al cliente relacionado;
                    </li>
                    <li>
                      para responder a sus consultas y atender sus solicitudes,
                      como envío de documentos que solicite o alertas por correo
                      electrónico;
                    </li>
                    <li>
                      para enviarle información importante sobre nuestra
                      relación con usted o sobre el Servicio, cambios en
                      nuestros términos, condiciones y políticas y/o información
                      administrativa.
                    </li>
                  </ul>
                  <p className="p1">
                    Participaremos en estas actividades para administrar nuestra
                    relación contractual con usted y/o cumplir con una
                    obligación legal.
                  </p>
                  <p className="p1"> </p>
                  <ul>
                    <li>
                      <strong>Lograr nuestros fines comerciales.</strong>
                    </li>
                    <li>
                      para el análisis de datos, por ejemplo, para mejorar la
                      eficiencia del Servicio;
                    </li>
                    <li>
                      para auditorías, para verificar que nuestros procesos
                      internos funcionan según lo previsto y que cumplen con los
                      requisitos legales, normativos o contractuales;
                    </li>
                    <li>
                      para fines de supervisión de fraude y seguridad, por
                      ejemplo, para detectar y prevenir ataques cibernéticos o
                      intentos de cometer robos de identidad;
                    </li>
                    <li>para desarrollar nuevos productos y servicios;</li>
                    <li>
                      para potenciar, mejorar o modificar nuestro sitio web o
                      productos y servicios;{" "}
                    </li>
                    <li>
                      para identificar las tendencias de uso del Servicio, por
                      ejemplo, comprender qué partes de nuestro Servicio son de
                      mayor interés para los usuarios; y
                    </li>
                    <li>
                      para determinar la efectividad de nuestras campañas
                      promocionales, para que podamos adaptar nuestras campañas
                      a las necesidades e intereses de nuestros usuarios.
                      <br />
                      <br />
                      Participaremos en estas actividades para administrar
                      nuestra relación contractual con usted, cumplir con una
                      obligación legal y/o porque tenemos un interés legítimo.
                    </li>
                  </ul>
                </div>
                <div> </div>
                <div>
                  <div>
                    <ul>
                      <li>
                        <strong>
                          Análisis de información personal para proporcionar
                          servicios personalizados.
                        </strong>
                      </li>
                      <li>
                        para comprenderle mejor, de modo que podamos
                        personalizar nuestras interacciones con usted y
                        proporcionarle información y/u ofertas adaptadas a sus
                        intereses;
                      </li>
                      <li>
                        para comprender mejor sus preferencias de modo que
                        podamos entregar contenido a través del Servicio que
                        creemos será relevante e interesante para usted.
                        <br />
                        <br />
                        <br />
                        Proporcionaremos servicios personalizados con su
                        consentimiento o porque tenemos un interés legítimo.
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p>
                    También divulgamos información recopilada a través del
                    Servicio:{" "}
                  </p>
                  <div>
                    <ul>
                      <li>
                        a nuestras filiales para los fines descritos en esta
                        Política de privacidad. Puede encontrar una lista de
                        nuestras filiales en{" "}
                        <a
                          href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                          target="_blank"
                        >
                          https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                        </a>{" "}
                        -acceder a los documentos presentados por Kenvue ante la Securities Exchange Commission. JNTL Consumer Health (Spain), S.L. se
                        responsabiliza de la administración de la información
                        personal tratada conjuntamente;
                      </li>
                      <li>
                        a nuestros socios externos con los que ofrecemos
                        promociones de marcas compartidas o comercialización
                        conjunta;
                      </li>
                      <li>
                        a nuestros proveedores de servicios externos que
                        proporcionan servicios, como hospedaje y moderación web,
                        hospedaje de aplicaciones para móviles, análisis de
                        datos, procesamiento de pagos, envío de pedidos,
                        provisión de infraestructura, servicios de TI, servicio
                        al cliente, servicios de provisión de correo electrónico
                        y correo directo, auditoría y otros servicios, a fin de
                        permitirles proporcionar servicios; y
                      </li>
                      <li>
                        tal y como permita la legislación aplicable, a un
                        tercero en caso de restructuración, fusión, venta, unión
                        de sociedades, sesión, transferencia u otra distribución
                        de cualquier parte de nuestro negocio, activo o acción
                        (incluyendo procedimientos de quiebra o similares).
                      </li>
                    </ul>
                  </div>
                  <div> </div>
                  <p>
                    Además, podemos emplear y divulgar su información según
                    consideremos necesario o adecuado: (a) para cumplir con
                    procesos legales o con la legislación aplicable, que puede
                    incluir leyes fuera de su país de residencia; (b) como
                    permita la legislación aplicable, para responder ante
                    solicitudes de las autoridades públicas y gubernamentales,
                    que pueden tratarse de autoridades fuera de su país de
                    residencia; (c) para hacer cumplir nuestros términos y
                    condiciones; y (d) para proteger nuestros derechos,
                    privacidad, seguridad o propiedad o los de nuestras
                    filiales, los de usted u los de otros. También podemos usar
                    y divulgar su información de otras formas tras obtener su
                    consentimiento para ello.
                  </p>
                  <p>
                    Podemos usar y divulgar información que recopilemos
                    automáticamente tal y como se describe anteriormente en “Uso
                    y Recopilación automática de información”.
                  </p>
                  <p>
                    Además, cuando lo permita la legislación aplicable, podemos
                    usar y divulgar información no personal con cualquier fin.
                    Si combinamos información personal en estado no
                    identificable con información que sí es identificable (como
                    combinar su nombre con su ubicación geográfica), trataremos
                    la información combinada como personal mientras esté
                    combinada.
                  </p>
                </div>
                <div> </div>
                <div>
                  <h2>OPCIONES Y ACCESO</h2>
                  <p>
                    <strong>
                      Sus opciones sobre el uso y la divulgación de su
                      información personal
                    </strong>
                  </p>
                  <p>
                    Le proporcionamos opciones sobre el uso y la divulgación de
                    su información personal con fines de marketing. Usted puede
                    optar por lo siguiente:
                  </p>
                  <div> </div>
                  <div>
                    <ul>
                      <li>
                        <strong>
                          Recibir nuestras comunicaciones comerciales:
                        </strong>{" "}
                        Si no desea recibir más nuestras comunicaciones
                        comerciales en el futuro, podrá oponerse a su recepción contactándonos 
                        por correo electrónico haciendo{" "}
                        <a
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                          target="_blank"
                        >
                          click aquí
                        </a>
                        . En su solicitud, le rogamos que facilite su nombre,
                        identifique la(s) modalidad(es) de comunicaciones de
                        marketing que no desea recibir e incluya la(s)
                        dirección/direcciones a la(s) que se la(s)
                        envió/enviaron. Por ejemplo, si no desea recibir
                        nuestras comunicaciones comerciales por correo
                        electrónico o correo directo, indíquelo y facilite su
                        nombre así como correo electrónico o dirección postal.
                        Además, puede oponerse a recibir nuestras comunicaciones
                        comerciales por correo electrónico siguiendo las
                        instrucciones de cancelación de la suscripción, que se
                        proporcionan en el mensaje.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Recepción de nuestros recordatorios:</strong> Si
                        no desea recibir más recordatorios médicos de nosotros
                        en el futuro, podrá oponerse a su recepción
                        contactándonos por correo electrónico haciendo{" "}
                        <a
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                          target="_blank"
                        >
                          click aquí
                        </a>
                        . En su comunicación, facilite su nombre y dirección de
                        correo electrónico o número de teléfono en el que recibe
                        los recordatorios.
                        <br />
                        <br />
                      </li>
                      <li>
                        <strong>Comunicación de su información personal a filiales y socios externos: 
                        </strong>{" "}
                        Si optó previamente por recibir comunicaciones
                        comerciales de nuestras filiales o contactándonos socios externos,
                        usted puede oponerse a que compartamos su información
                        personal con dichas partes para fines publicitarios en
                        el futuro por correo electrónico haciendo{" "}
                        <a
                          href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL"
                          target="_blank"
                        >
                          click aquí
                        </a>
                        . En su comunicación, le rogamos que nos indique que no
                        debemos comunicar su información personal a nuestras
                        filiales y/o socios externos con fines publicitarios, e
                        incluya su nombre y dirección de correo electrónico.
                      </li>
                    </ul>
                  </div>
                  <p>
                    Trataremos de cumplir con su(s) solicitud(es) lo antes
                    posible. Cabe señalar que si se opone tal como se describió
                    anteriormente, no podremos eliminar su información personal
                    de las bases de datos de nuestras filiales con las que ya
                    hayamos compartido su información personal (es decir, a la
                    fecha de su solicitud de oposición). No obstante, haremos
                    esfuerzos razonables para informar a nuestras filiales de su solicitud.
                    Tenga en cuenta que, si se opone a recibir comunicaciones comerciales de nosotros,
                    sigue siendo posible que le enviemos importantes
                    comunicaciones administrativas y sobre transacciones,
                    respecto de los cuales no puede oponerse.
                  </p>
                </div>
                <div>
                  <p>
                    <strong>
                      Cómo puede acceder, cambiar o eliminar su información
                      personal
                    </strong>
                  </p>
                  <p>
                    Si usted quisiera revisar, corregir, actualizar, limitar o
                    eliminar su información personal, o si quisiera solicitar
                    una copia electrónica de su información personal con la
                    finalidad de transmitirla a otra empresa (hasta donde la
                    legislación aplicable le conceda estos derechos) contáctenos
                    por correo electrónico haciendo{" "}
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL "
                      target="_blank"
                    >
                      click aquí
                    </a>
                    . Responderemos a su solicitud tan pronto como sea
                    razonablemente posible y no más tarde de un mes tras la
                    recepción. Para ayudarle en caso de demora en nuestra
                    respuesta, se le notificará rápidamente y se le
                    proporcionará la fecha en la que le daremos respuesta.{" "}
                  </p>
                </div>
                <div>
                  <h2>TRANSFERENCIA INTERNACIONAL</h2>
                  <p>
                    Su información personal podrá almacenarse y procesarse en
                    cualquier país en que tengamos instalaciones o proveedores
                    de servicios, y al usar nuestro Servicio o proporcionarnos
                    su consentimiento (cuando la ley lo exija), su información
                    podrá ser transferida a países fuera de su país de
                    residencia, incluyendo los Estados Unidos, que podrán
                    establecer diferentes normas de protección de datos
                    distintas a las de su país. Sin embargo, existen medidas contractuales
                    apropiadas y de otro tipo implementadas para proteger la
                    información personal cuando se transfiere a nuestras
                    filiales o socios externos en otros países.{" "}
                  </p>
                  <p>
                    La Comisión Europea reconoce a algunos países fuera del
                    Espacio Económico Europeo (EEE) como países que proporcionan
                    un nivel de protección de datos adecuado de acuerdo con los
                    estándares del EEE (la lista completa de estos países está
                    disponible{" "}
                    <a
                      href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_es "
                      target="_blank"
                    >
                      aquí)
                    </a>
                    . En el caso de transferencias desde el EEE a países
                    considerados no adecuados por la Comisión Europea, nos hemos
                    asegurado de que existan medidas adecuadas implementadas,
                    incluyendo la garantía de que el receptor esté vinculado por
                    cláusulas contractuales tipo de la UE, para proteger su
                    Información personal. Usted puede obtener una copia de estas
                    medidas contactando con nuestro Delegado de Protección de
                    Datos, de acuerdo con las instrucciones en la sección
                    “Contactar con nosotros” (Contacting Us) abajo.
                  </p>
                </div>
                <h2>SEGURIDAD</h2>
                <p>
                  Buscamos implementar medidas razonables organizativas,
                  técnicas y administrativas diseñadas para proteger la
                  información personal bajo nuestro control. Lamentablemente, no
                  se puede garantizar la seguridad total de ninguna transmisión
                  de datos por Internet o de sistemas de almacenamiento de
                  datos. Si tiene motivos para creer que su interacción con
                  nosotros ya no es segura (por ejemplo, si considera que la
                  seguridad de una cuenta que tiene con nosotros ha sido
                  comprometida), le rogamos que nos lo notifique de inmediato de
                  acuerdo con las instrucciones en la sección “Contactar con nosotros” (Contacting Us) abajo.
                </p>
                <h2>PERIODO DE RETENCIÓN</h2>
                <p>
                  Retendremos su información personal durante el tiempo que sea
                  necesario o esté permitido de acuerdo con el fin o los fines
                  para los que se obtuvo. Los criterios utilizados para
                  determinar nuestros periodos de retención incluyen: (i) el
                  periodo de tiempo por el que tenemos una relación con usted y
                  le proporcionamos el Servicio; (ii) si existe una obligación
                  legal a la que estamos sometidos; y (iii) si la retención es
                  aconsejable teniendo en cuenta nuestra posición legal (lo
                  relativo a los plazos aplicables de prescripción, litigio o
                  investigaciones reguladoras).
                </p>
                <h2>SITIOS Y SERVICIOS DE TERCEROS</h2>
                <p>
                  El Servicio puede contener vínculos a sitios de terceros. 
                  Esta Política de privacidad no trata ni tampoco nos
                  responsabilizamos por la privacidad, información o
                  prácticas de terceros, incluidos terceros que operan cualquier
                  sitio o servicio en línea (entre otros, cualquier
                  aplicación) que esté disponible a través de este Servicio o de
                  un enlace incluido en este Servicio. La disponibilidad de
                  cualquier sitio o presencia en el Servicio, o la inclusión de
                  un enlace a estos, no implica su aprobación por nosotros o
                  nuestras filiales
                </p>
                <div>
                  <h2>CONTÁCTENOS</h2>
                  <p>
                    JNTL Consumer Health (Spain), S.L., en el Paseo de las Doce
                    Estrellas, 5-7, 28042 Madrid, es la empresa responsable de
                    la recopilación, uso y divulgación de información personal
                    de acuerdo con esta Política de privacidad.
                  </p>
                  <p>
                    Si tiene alguna pregunta sobre esta Política de privacidad,
                    contáctenos por correo electrónico haciendo{" "}
                    <a
                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pL "
                      target="_blank"
                    >
                      click aquí
                    </a>
                    , o por correo postal a la siguiente dirección:
                  </p>
                  <p>
                    JNTL Consumer Health (Spain), S.L.
                    <br />
                    Servicio de Atención al Consumidor
                    <br />
                    División Consumo
                    <br />
                    Paseo de las Doce Estrellas, 5-7
                    <br />
                    28042 Madrid
                  </p>
                  <p>
                    También puede ponerse en contacto con el Delegado de
                    Protección de Datos a cargo de su país o región, si procede,
                    a través de{" "}
                    <a href="mailto:emeaprivacy@kenvue.com">
                    emeaprivacy@kenvue.com
                    </a>
                    .
                  </p>
                </div>
                <div> </div>
                <div>
                  <h2>
                    PRESENTAR UNA RECLAMACIÓN ANTE LA AUTORIDAD REGULADORA
                  </h2>
                  <p>
                    Usted puede presentar una reclamación ante una autoridad
                    supervisora competente en su país o región. Por favor haga{" "}
                    <a
                      href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
                      target="_blank"
                    >
                      clic aquí
                    </a>{" "}
                    para acceder a la información de contacto de dichas
                    autoridades.
                  </p>
                </div>
                <div> </div>
                <div>
                  <h2>ACTUALIZACIONES DE ESTA POLÍTICA DE PRIVACIDAD</h2>
                  <p>
                    Podremos modificar esta Política de privacidad. Toda
                    modificación de nuestra Política de privacidad entrará en
                    vigor con la publicación de la Política de privacidad
                    revisada en el Servicio. Si utiliza el Servicio después de
                    estas modificaciones, esto significa que acepta la Política
                    de Privacidad revisada. Recomendamos que revise la Política
                    de Privacidad de forma regular cuando visite el Servicio.
                  </p>
                  <p>
                    Esta Política se actualizó por última vez el  04/05/2023.
                  </p>
                  <p> </p>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;